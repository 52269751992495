import LRU from 'lru-cache';

const path = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  REGISTER: 'register',
};

const NUMBER = {
  QUANTITY: {
    FIVE: 5,
    HUNDRED: 100,
  },
  TIME: {
    ONE_MIN: 1000 * 60,
  },
};

const authCache = new LRU({
  max: NUMBER.QUANTITY.HUNDRED,
  ttl: NUMBER.QUANTITY.FIVE * NUMBER.TIME.ONE_MIN,
});

export default (axios, auth) => ({
  /**
   * @param {string} email
   * @param {string} password
   */
  login(payload) {
    return axios.post(`${auth}/${path.LOGIN}`, payload);
  },
  logout() {
    return axios.post(`${auth}/${path.LOGOUT}`);
  },
  /**
   * @param {string} email
   */
  forgotPassword(payload) {
    return axios.post(`${auth}/${path.FORGOT_PASSWORD}`, payload);
  },
  resetPassword(payload) {
    return axios.post(`${auth}/${path.RESET_PASSWORD}`, payload);
  },
  register(payload) {
    return axios.post(`${auth}/${path.REGISTER}`, payload);
  },
  emailVerify(url) {
    return axios.post(url);
  },
});
