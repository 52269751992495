import React, { Suspense, lazy, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// components
import LoadingScreen from '../components/LoadingScreen';

import path, { Route } from './path';

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const [
  News,
  NewDetail,
  Jobs,
  JobsDetail,
  JobsResume,
  NotFound,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  EmailConfirmed,
  MemberProfile,
  RevisePassword,
  UserJobs,
  UserJobsDetail,
  UserSchedule,
  UserScheduleDetail,
  UserWork,
  UserLeave,
  UserSubstitute,
] = [
  import('../pages/PageNews'),
  import('../pages/PageNews/Detail'),
  import('../pages/PageJobs'),
  import('../pages/PageJobs/Detail'),
  import('../pages/PageJobs/Resume'),
  import('../pages/Page404'),
  import('../pages/PageLogin'),
  import('../pages/PageRegister'),
  import('../pages/PageForgotPassword'),
  import('../pages/PageResetPassword'),
  import('../pages/PageEmailConfirmed'),
  import('../pages/PageMemberProfile'),
  import('../pages/PageRevisePassword'),
  import('../pages/PageUserJobs'),
  import('../pages/PageUserJobs/Detail'),
  import('../pages/PageUserSchedule'),
  import('../pages/PageUserSchedule/Detail'),
  import('../pages/PageUserWork'),
  import('../pages/PageUserLeave'),
  import('../pages/PageUserSubstitute'),
].map((page) => Loadable(lazy(() => page)));

export default function Router({ loginUser, setLoginUser }) {
  const { isLogin } = loginUser;
  const mainRoute = useMemo(() => {
    // 暫時
    const main = [
      {
        element: (
          <Navigate
            to={(() => {
              return path.NEWS;
            })()}
          />
        ),
        index: true,
      },
    ];
    main.push(
      {
        path: path.NEWS,
        element: <News />,
      },
      {
        path: path.NEWS_DETAIL,
        element: <NewDetail />,
      },
      {
        path: path.JOBS,
        element: <Jobs loginUser={loginUser} />,
      },
    );

    if (isLogin) {
      main.push(
        {
          path: path.JOBS_DETAIL,
          element: <JobsDetail />,
        },
        {
          path: path.JOBS_RESUME,
          element: <JobsResume loginUser={loginUser} />,
        },
        {
          path: path.USER_PROFILE,
          element: <MemberProfile loginUser={loginUser} />,
        },
        {
          path: path.USER_REVISE_PASSWORD,
          element: <RevisePassword loginUser={loginUser} />,
        },
        {
          path: path.USER_JOBS,
          element: <UserJobs loginUser={loginUser} />,
        },
        {
          path: path.USER_JOBS_DETAIL,
          element: <UserJobsDetail loginUser={loginUser} />,
        },
        {
          path: path.USER_SCHEDULE,
          element: <UserSchedule loginUser={loginUser} />,
        },
        {
          path: path.USER_SCHEDULE_DETAIL,
          element: <UserScheduleDetail loginUser={loginUser} />,
        },
        {
          path: path.USER_WORK,
          element: <UserWork loginUser={loginUser} />,
        },
        {
          path: path.USER_LEAVE,
          element: <UserLeave loginUser={loginUser} />,
        },
        {
          path: path.USER_SUBSTITUTE,
          element: <UserSubstitute loginUser={loginUser} />,
        },
      );
    }

    return main;
  }, [isLogin]);

  const routes = [
    {
      path: Route.login,
      element: <Login setLoginUser={setLoginUser} loginUser={loginUser} />,
    },
    {
      path: Route.register,
      element: <Register />,
    },
    {
      path: Route.forgotPassword,
      element: <ForgotPassword />,
    },
    {
      path: Route.resetPassword,
      element: <ResetPassword />,
    },
    {
      path: path.EMAIL_CONFIRMED,
      element: <EmailConfirmed />,
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: Route.notFound, element: <NotFound /> },
        { path: Route.all, element: <Navigate to={path.ROOT} replace /> },
      ],
    },
    {
      path: Route.root,
      element: (
        <DashboardLayout loginUser={loginUser} setLoginUser={setLoginUser} />
      ),
      children: mainRoute,
    },
  ];

  return useRoutes(routes);
}
