export default (axios, job) => ({
  /**
   * @param {number} limit 每頁顯示頁數
   * @param {number} page 第幾頁
   * @param {string} category_id 類別
   * @param {string} sort_col 排序key
   * @param {string} sort 排序方式
   */
  getJobs(params) {
    return axios.get(`${job}/job-vacancy`, { params });
  },
  getJob(id) {
    return axios.get(`${job}/job-vacancy/${id}`);
  },
  applyResume(id, data) {
    return axios.post(`${job}/job-vacancy/${id}/apply-resume`, data);
  },
});
