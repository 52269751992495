const path = {
  PROFILE: 'profile',
  JOB: 'job-vacancy',
  survey: 'work-schedule-survey',
  myWork: 'my-work-schedule',
  leave: 'leave-form',
  leaveSchedule: 'work-schedule-item',
  employee: 'find-employee',
  substitue: 'asked-substitue',
  employeeProfile: 'employee-profile',
};

export default (axios, user) => ({
  getMe() {
    return axios.get(`${user}`);
  },
  getMeProfile() {
    return axios.get(`${user}/${path.PROFILE}`);
  },
  updateProfile(payload) {
    return axios.patch(`${user}/${path.PROFILE}`, payload);
  },
  getJobs(params) {
    return axios.get(`${user}/${path.JOB}`, params);
  },
  getScheduleSurveys(params) {
    return axios.get(`${user}/${path.survey}`, params);
  },
  getScheduleSurvey(id) {
    return axios.get(`${user}/${path.survey}/${id}`);
  },
  updateScheduleSurvey(id, payload) {
    return axios.patch(`${user}/${path.survey}/${id}`, payload);
  },
  getMyWork(params) {
    return axios.get(`${user}/${path.myWork}`, { params });
  },
  getLeave(params) {
    return axios.get(`${user}/${path.leave}`, { params });
  },
  getLeaveSchedule() {
    return axios.get(`${user}/${path.leave}/${path.leaveSchedule}`);
  },
  findLeaveEmployee(payload) {
    return axios.post(`${user}/${path.leave}/${path.employee}`, payload);
  },
  askLeave(payload) {
    return axios.post(`${user}/${path.leave}`, payload);
  },
  getSubstitute(params) {
    return axios.get(`${user}/${path.substitue}`, { params });
  },
  substituteAgree(id) {
    return axios.post(`${user}/${path.substitue}/${id}/agree`);
  },
  substituteDisagree(id) {
    return axios.post(`${user}/${path.substitue}/${id}/disagree`);
  },
  getEmployeeProfile(id) {
    return axios.get(`${user}/${path.JOB}/${id}/${path.employeeProfile}`);
  },
  addEmployeeProfile(id, payload) {
    return axios.post(
      `${user}/${path.JOB}/${id}/${path.employeeProfile}`,
      payload,
    );
  },
});
