import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from '@mui/material';
// components
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import path from 'src/routes/path';
import axios from 'src/plugins/api/axios';
import cookies, { KeyCookie } from 'src/plugins/cookie/cookie';

const { auth } = axios;

export default function AccountPopover({ loginUser, setLoginUser }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (route) => {
    if (route) {
      navigate(route);
    }
    setOpen(null);
  };

  const logout = async () => {
    await auth.logout();
    cookies.remove(KeyCookie.timeKeeperClient);
    setLoginUser({ isLogin: false });
    navigate(path.ROOT);
  };

  const login = () => {
    navigate(path.LOGIN);
  };

  const MENU_OPTIONS = useMemo(() => {
    const res = [];
    if (loginUser?.isLogin) {
      res.push({ label: '個人資料', linkTo: path.USER_PROFILE });
    }
    return res;
  }, [loginUser?.isLogin]);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {loginUser?.name || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {loginUser?.email || ''}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleClose(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {loginUser?.isLogin ? (
          <MenuItem sx={{ m: 1 }} onClick={logout}>
            登出
          </MenuItem>
        ) : (
          <MenuItem sx={{ m: 1 }} onClick={login}>
            登入
          </MenuItem>
        )}
      </MenuPopover>
    </>
  );
}
