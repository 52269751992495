import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import { useLoadingService } from 'services/LoadingService';
import Router from '../routes/index';
import cookies, { KeyCookie } from '../plugins/cookie/cookie';
import path from '../routes/path';
// import jwtDecode from 'jwt-decode';
import axios from '../plugins/api/axios';

const { user } = axios;

export default () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loginUser, setLoginUser] = useState({
    isLogin: false,
  });

  const checkAccessTokenExp = () => {
    const accessToken = cookies.get(KeyCookie.timeKeeperClient);

    if (accessToken) {
      // const { exp } = jwtDecode(accessToken);
      // if (exp && exp * 1000 < Date.now()) {
      //   cookies.remove(KeyCookie.timeKeeperClient);
      //   return false;
      // }
      setLoginUser({
        ...loginUser,
      });
      return true;
    }
    cookies.remove(KeyCookie.timeKeeperClient);
    return false;
  };

  const handleLoginPermission = async () => {
    if (checkAccessTokenExp()) {
      const response = await user.getMe();

      if (!response) {
        cookies.remove(KeyCookie.timeKeeperClient);
      } else {
        const accessToken = cookies.get(KeyCookie.timeKeeperClient);
        setLoginUser({
          isLogin: true,
          ...response,
          token: accessToken,
        });
      }
      if (Object.values(path).includes(pathname)) {
        if (pathname === path.LOGIN || pathname === path.REGISTER) {
          navigate(path.NEWS);
          return;
        }
        navigate(pathname);
      }
      return;
    }
  };

  useEffect(() => {
    handleLoginPermission();
  }, []);

  return <Router loginUser={loginUser} setLoginUser={setLoginUser} />;
};
