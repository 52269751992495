import instance from './instance';
import auth from '../../api/auth';
import user from '../../api/user';
import news from '../../api/news';
import job from '../../api/job';

export const URL = {
  timeKeeperDomain: `${process.env.REACT_APP_BASE_URL}/api/front`,
};

export default {
  auth: auth(instance, `${URL.timeKeeperDomain}/auth`),
  user: user(instance, `${URL.timeKeeperDomain}/user`),
  news: news(instance, `${URL.timeKeeperDomain}/news`),
  job: job(instance, `${URL.timeKeeperDomain}`),
};
