export default (axios, news) => ({
  /**
   * @param {number} limit 每頁顯示頁數
   * @param {number} page 第幾頁
   * @param {string} category_id 類別
   * @param {string} sort_col 排序key
   * @param {string} sort 排序方式
   */
  getNews(params) {
    return axios.get(`${news}/article`, { params });
  },
  /**
   * @param {string} id 最新消息Id
   */
  getNewsById(id) {
    return axios.get(`${news}/article/${id}`);
  },
});
