export const Route = {
  all: '*',
  root: '/',
  notFound: '404',
  login: 'login',
  news: 'news',
  jobs: 'job-vacancies',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  emailConfirmed: 'email-confirmed',
  user: 'user',
};

export const RouteName = {
  all: '全部',
  root: 'timeKeeper',
  notFound: '找不到',
  login: '登入',
  register: '註冊',
  news: '最新消息',
  jobs: '職缺公告',
  forgotPassword: '忘記密碼',
  resetPassword: '重置密碼',
  emailConfirmed: '信箱驗證',
  user: '會員中心',
};

export const FirstRoute = {
  create: 'create',
  id: ':id',
  profile: 'profile',
  revisePassword: 'revisePassword',
  jobs: 'jobs',
  schedule: 'schedule',
  myWork: 'myWork',
  leave: 'leave',
  substitute: 'substitute',
};

export const FirstRouteName = {
  // newsManage: '最新消息',
  profile: '個人資料',
  revisePassword: '修改密碼',
  jobs: '應徵列表',
  schedule: '班表調查',
  myWork: '個人班表',
  leave: '請假',
  substitute: '代班',
};

export const SecondRoute = {
  edit: 'edit',
  resume: 'resume',
};

export const SecondRouteName = {
  edit: '編輯',
};

const path = (path) => {
  return path ? `${Route.root}${path.join('/')}` : Route.root;
};

export default {
  ROOT: path(),
  NOT_FOUND: path([Route.notFound]),
  LOGIN: path([Route.login]),
  REGISTER: path([Route.register]),
  NEWS: path([Route.news]),
  NEWS_DETAIL: path([Route.news, FirstRoute.id]),
  JOBS: path([Route.jobs]),
  JOBS_DETAIL: path([Route.jobs, FirstRoute.id]),
  JOBS_RESUME: path([Route.jobs, FirstRoute.id, SecondRoute.resume]),
  FORGOT_PASSWORD: path([Route.forgotPassword]),
  RESET_PASSWORD: path([Route.resetPassword]),
  EMAIL_CONFIRMED: path([Route.emailConfirmed]),
  USER_PROFILE: path([Route.user, FirstRoute.profile]),
  USER_REVISE_PASSWORD: path([Route.user, FirstRoute.revisePassword]),
  USER_JOBS: path([Route.user, FirstRoute.jobs]),
  USER_JOBS_DETAIL: path([Route.user, FirstRoute.jobs, FirstRoute.id]),
  USER_SCHEDULE: path([Route.user, FirstRoute.schedule]),
  USER_SCHEDULE_DETAIL: path([
    Route.user,
    FirstRoute.schedule,
    FirstRoute.id,
    SecondRoute.edit,
  ]),
  USER_WORK: path([Route.user, FirstRoute.myWork]),
  USER_LEAVE: path([Route.user, FirstRoute.leave]),
  USER_SUBSTITUTE: path([Route.user, FirstRoute.substitute]),
};

export const Auth = {
  // POINT_MANAGE_READ: 'point_manage:read',
  // POINT_MANAGE_EDIT: 'point_manage:edit',
};
