import Cookies from 'js-cookie';

export const KeyCookie = {
  timeKeeperClient: 'timeKeeper_front_cookie',
};

const map = new Map();

const tryGetCookie = (key) => {
  if (map.has(key)) {
    return map.get(key);
  }
  const cookieData = Cookies.get(key);
  cookieData && map.set(key, cookieData);
  return cookieData;
};

export default {
  get(key) {
    const cookieData = tryGetCookie(key);
    if (cookieData) {
      return JSON.parse(cookieData);
    }
    return cookieData;
  },

  set(key, data) {
    Cookies.set(key, JSON.stringify(data));
  },

  remove(key) {
    Cookies.remove(key);
    map.delete(key);
  },
};
